<template>
  <filter-location-block
    show-tooltips
    module-name="influencerDiscovery"
    :show-audience="shouldShowAudience"
    :show-states="shouldShowStates"
    :only-countries="onlyCountries"
    :applied-filters="appliedFilters"
    :is-filter-applied="isFilterApplied"
    @submit="handleSubmit"
    @update="handleUpdate"
    @remove="handleRemove"
  />
</template>

<script>
// Import child components
const FilterLocationBlock = () => import(/* webpackChunkName: "filter-location" */ "@/blocks/common/filters/FilterLocation.vue")

// Export the SFC
export default {
  // Name of the component
  name: "FilterLocation",

  // Accept incoming data from parent
  props: {
    platform: {
      type: String,
      required: true
    }
  },

  // Register the components
  components: {
    FilterLocationBlock
  },

  // Define readonly data variables
  computed: {
    /**
     * Get whether to show only countries in the search result
     *
     * @returns {Boolean}
     */
    onlyCountries() {
      // If we're not searching in instagram, then show only countries
      return this.platform !== "instagram"
    },

    /**
     * Get a list of all applied filters
     *
     * @returns {Array}
     */
    appliedFilters() {
      return this.$store.getters['influencerDiscovery/findFiltersByType']("location")
    },

    /**
     * Check if we've applied this filter already at-least once
     *
     * @returns {Boolean}
     */
    isFilterApplied() {
      return Boolean(this.appliedFilters.length)
    },

    /**
     * If this is not snapchat, let the user choose the audience
     *
     * @returns {Boolean}
     */
    shouldShowAudience() {
      return !["snapchat", "linkedin"].includes(this.platform)
    },

    /**
     * If this is not snapchat, let the user choose the states
     *
     * @returns {Boolean}
     */
    shouldShowStates() {
      return !["snapchat", "linkedin"].includes(this.platform)
    }
  },

  // Define local method functions
  methods: {
    /**
     * Handle the event when the form is submitted
     *
     * @param {Object} data
     * @returns {void}
     */
    handleSubmit(data) {
      this.$store.dispatch('influencerDiscovery/addFilter', data)
    },

    /**
     * Handle the event when the form is updated
     *
     * @param {Object} data
     * @returns {void}
     */
    handleUpdate(data) {
      this.$store.dispatch('influencerDiscovery/updateFilter', data)
    },

    /**
     * Handle the event when the filter is deleted
     *
     * @param {Object} data
     * @returns {void}
     */
    handleRemove(data) {
      this.$store.dispatch('influencerDiscovery/removeFilter', data.id)
    }
  }
}
</script>
